<template>
  <div>
    <el-card>
      <div class="topDiv">
        <el-input v-model="params.name" placeholder="请输入内容"></el-input>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addClick()">添加</el-button>
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="奖池名称" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="tenantName" label="公司名称" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="description" label="玩法" min-width="150" :show-overflow-tooltip="true">
          <!--写死了。。。-->
          <template slot-scope="scope">盲盒</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" min-width="300">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row)">删除</el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="editClick(scope.row)">编辑奖品</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>

    <!--  编辑奖池/ 添加奖池-->
    <el-dialog :title="rowData ? '编辑奖池' : '添加奖池'" :visible.sync="poolDia" v-if="poolDia" width="50%" @close="poolDia = false">
      <poolDia :rowData="rowData" @quXiaoClick="poolDia = false" @diaQueDingClick="diaQueDingClick"></poolDia>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import poolDia from '@/components/poolDia.vue'

export default {
  components: { pagination, poolDia },
  mixins: [commonMixin],
  data() {
    return {
      poolDia: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        name: '', // 搜索关键词
      },
      records: [],
      total: 0,
      rowData: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取列表数据
    async getData() {
      const res = await this.$API.jiangChi.getPoolList(this.params)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = parseInt(total)
    },
    searchClick() {
      this.params.pageNum = 1
      this.getData()
    },
    // 编辑奖品
    editClick(row) {
      localStorage.setItem('jiangChiRow', JSON.stringify(row))
      this.$router.push('/poolGuanLi/jiangPinList')
    },
    // 添加
    addClick() {
      this.rowData = ''
      this.poolDia = true
    },
    // 编辑
    updateClick(row) {
      this.rowData = row
      this.poolDia = true
    },
    // 新增、编辑奖池 弹框上 确定 click
    diaQueDingClick() {
      this.poolDia = false
      this.getData()
    },
    //  删除 奖池
    deleteItem(item) {
      const params = {
        id: item.id,
        tenantName: item.tenantName,
      }
      this.$confirm('此操作将永久删除该奖池, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (res) => {
          if (res == 'confirm') {
            const res = await this.$API.jiangChi.delPool(params)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$message.success(res.data)
            this.params.pageNum = 1
            this.getData()
          } else {
            // resolve(true)
          }
        })
        .catch((err) => err)
    },
  },
}
</script>

<style lang="scss" scoped>
.topDiv {
  display: flex;
  .el-input {
    width: 200px;
    margin-right: 20px;
  }
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
